<template>
  <base-dialog-form
    :width="450"
    :dialog="dialog"
    :loading="loading"
    :title="`${permission ? 'Edit' : 'Create'} permission`"
    actionText="Save permission details"
    @close="$emit('close')"
    @submit="submit()"
  >
    <template #content>
      <v-select
        dense
        outlined
        persistent-hint
        label="Service *"
        :items="microservices.data"
        class="rounded-lg"
        item-text="name"
        item-value="microservice_uid"
        v-model="permissionObj.microservice_uid"
        :hint="errors.get('microservice_uid')"
        :error="errors.has('microservice_uid')"
        @input="errors.clear('microservice_uid')"
      ></v-select>

      <v-divider></v-divider>

      <v-checkbox
        v-model="hasSubject"
        label="Permission is attached to a CRUD subject"
      ></v-checkbox>

      <div v-if="hasSubject">
        <v-text-field
          dense
          outlined
          persistent-hint
          label="Subject name"
          class="rounded-lg"
          v-model="permissionObj.subject"
          :hint="errors.get('subject')"
          :error="errors.has('subject')"
          @input="errors.clear('subject')"
        ></v-text-field>
      </div>

      <div v-if="!hasSubject">
        <v-text-field
          dense
          outlined
          persistent-hint
          label="Name"
          class="rounded-lg"
          v-model="permissionObj.display_name"
          :hint="errors.get('display_name')"
          :error="errors.has('display_name')"
          @input="errors.clear('display_name')"
        ></v-text-field>

        <v-textarea
          dense
          outlined
          persistent-hint
          label="Description *"
          class="rounded-lg"
          v-model="permissionObj.description"
          :hint="errors.get('description')"
          :error="errors.has('description')"
          @input="errors.clear('description')"
        ></v-textarea>
      </div>

      <v-divider></v-divider>

      <v-checkbox
        v-model="permissionObj.is_essential"
        label="Permission is common"
        hide-details="auto"
      ></v-checkbox>

      <v-checkbox
        v-model="permissionObj.is_internal"
        label="Limit access to platform users"
      ></v-checkbox>
    </template>
  </base-dialog-form>
</template>

<script>
import Permission from "@/libs/iam/Permission"
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    dialog: {
      required: true,
      type: Boolean,
    },

    permission: {
      type: Object
    }
  },

  data () {
    return {
      loading: false,
      hasSubject: false,
      permissionObj: new Permission(),
    }
  },

  computed: {
    ...mapGetters({
      microservices: 'getMicroservices',
    }),

    errors () {
      return this.permissionObj.form.errors
    },
  },

  methods: {
    ...mapActions([
      'setMicroservices',
      'setPermissions',
    ]),

    submit () {
      if (!this.loading) {
        this.loading = true
        !this.permission ? this.store() : this.update()
      }
    },

    store () {
      this.permissionObj.store().then(() => {
        this.setPermissions().then(() => {
          this.$emit('close')
        })
      }).finally(() => {
        this.loading = false
      })
    },

    update () {
      this.permissionObj.update(this.permission.permission_uid).then(() => {
        this.setPermissions().then(() => {
          this.$emit('close')
        })
      }).finally(() => {
        this.loading = false
      })
    },
  },

  mounted () {
    this.setMicroservices()
  }
}
</script>